<template>
  <not-found v-if="people === null"/>
  <div class="profile is_page" v-else>
    <div class="profiles_banner">
      <img
        :src="'https://static.bcdcnt.net/assets/images/group/group-cover-1.jpg'"
        alt=""
      />
    </div>
    <div class="profiles_content">
      <div class="profile_avatar">
        <div class="profile_avatar_holder">
          <a href="#avatarModal" uk-toggle :title="people.title">
            <img
              :src="
                people.avatar
                  ? people.avatar.url
                  : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'
              "
              :alt="people.title"
            />
          </a>
        </div>
        <div class="icon_change_photo" hidden>
          <ion-icon name="camera" class="text-xl"></ion-icon>
        </div>
      </div>
      <div class="profile_info">
        <h1>{{ people.title }}</h1>
        <p>{{ people.rank }}</p>
        <p>
          <span :uk-tooltip="'title: ' + thousandFormat(people.total_listens) + ' lượt nghe'"><i class="uil uil-headphones"></i> {{ numberFormat(people.total_listens) }}</span>
        </p>
      </div>

      <div v-if="canUpdateAvatar || canManagePeople" class="float-right">
        <a href="#">
          <i
            class="icon-feather-more-horizontal text-2xl hover:bg-gray-200 rounded-full p-2 transition -mr-1 dark:hover:bg-gray-700"
          ></i>
        </a>
        <div
          class="bg-white w-62 shadow-md mx-auto p-2 mt-12 rounded-md text-gray-500 hidden text-base border border-gray-100 dark:bg-gray-900 dark:text-gray-100 dark:border-gray-700"
          uk-drop="mode: click;pos: bottom-right;animation: uk-animation-slide-bottom-small"
        >
          <ul class="space-y-1">
            <li class="ml-3" v-if="canUpdateAvatar">
              <router-link
                uk-toggle="target: #updateImageModal"
                class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
                to="#"
              >
                <i class="uil-plus"></i> Ảnh đại diện
              </router-link>
            </li>
            <li v-if="canManagePeople" class="ml-3">
              <router-link
                :to="{ name: editRouter, params: { id: people.id } }"
                class="uk-drop-close flex items-center px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
              >
                <i class="uil-pen mr-1"></i>Sửa
              </router-link>
            </li>
            <li class="ml-3" v-if="canManagePeople">
              <div
                class="flex px-3 py-2 hover:bg-gray-200 hover:text-gray-800 rounded-md dark:hover:bg-gray-800"
              >
                <label class="switch flex items-center mb-4">
                  <input
                    type="checkbox"
                    v-model="people.is_sticky"
                    v-on:change="stickOrUnstick"
                  />
                  <span class="switch-button"></span>
                </label>
                <span class="ml-10">Làm nổi bật</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <update-image-modal
      title="Cập nhật ảnh đại diện"
      v-on:updateImageDone="loadPeople"
      update-type="updateAvatar"
      :type="type"
      :id="people.id"
    />

    <div id="avatarModal" class="create-post" uk-modal>
      <div
        class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical rounded-lg p-0 lg:w-5/12 relative shadow-2xl uk-animation-slide-bottom-small"
      >
        <div class="text-center py-3 border-b">
          <h3 class="text-lg font-semibold">{{ people.title }}</h3>
          <button
            class="avatarClose uk-modal-close-default bg-gray-100 rounded-full p-2.5 right-2"
            type="button"
            uk-close
            uk-tooltip="title: Close ; pos: bottom ;offset:7"
          ></button>
        </div>

        <div class="px-5 py-2">
          <img
            class="w-full"
            :src="
              people.avatar
                ? people.avatar.url
                : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'
            "
            :alt="people.title"
          />

          <div
            v-if="people.avatar && people.avatar.user"
            class="mt-2 text-right"
          >
          Ảnh sử dụng:
            <router-link
              class="font-semibold"
              :to="{
                name: 'UserDetail',
                params: { id: people.avatar.user.id },
              }"
              :title="people.avatar.user.username"
            >
              {{ people.avatar.user.username }}
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <nav class="responsive-nav border-t -mb-0.5 lg:pl-2">
      <div
        class="flex justify-between lg:border-t flex-col-reverse lg:flex-row"
      >
        <nav class="responsive-nav pl-2 is_ligh -mb-0.5 border-transparent">
          <ul
            uk-switcher="connect: #timeline-tab; animation: uk-animation-fade"
          >
            <li><a href="#">Thông tin</a></li>
            <li>
              <a href="#"
                >Tân nhạc<span>{{ songs.paginatorInfo.total }}</span></a
              >
            </li>
            <li>
              <a href="#"
                >Dân ca<span>{{ folks.paginatorInfo.total }}</span></a
              >
            </li>
            <li>
              <a href="#"
                >Khí nhạc<span>{{ instrumentals.paginatorInfo.total }}</span></a
              >
            </li>
            <li>
              <a href="#"
                >Tiếng thơ<span>{{ poems.paginatorInfo.total }}</span></a
              >
            </li>
            <li>
              <a href="#"
                >Tư liệu<span>{{ documents.paginatorInfo.total }}</span></a
              >
            </li>
          </ul>
        </nav>
      </div>
    </nav>
  </div>

  <div class="uk-switcher lg:mt-8 mt-4" id="timeline-tab" v-if="people">
    <div class="md:flex md:space-x-6">
      <div class="space-y-5 flex-shrink-0 md:w-7/12">
        <div class="card p-5 mb-5">
          <div v-html="people.content" class="content"></div>
        </div>

        <comment
          :comment-id="commentId"
          :object-type="type"
          :object-id="people.id"
          :slug="slug"
          field="slug"
          type="String"
          v-on:replaceImageFromCommentDone="handleReplaceImageFromCommentDone"
        />
      </div>
      <div class="w-full flex-shirink-0">
        <div class="card p-5 mb-5">
          <h1 class="block text-lg font-bold">Tóm tắt</h1>
          <div class="space-y-4 mt-3">
            <div class="flex items-center space-x-3" v-if="people.real_name">
              <ion-icon
                name="alert-circle"
                class="bg-gray-100 p-1.5 rounded-full text-xl"
              ></ion-icon>
              <div class="flex-1">
                <div>{{ people.real_name }}</div>
              </div>
            </div>
            <div class="flex items-center space-x-3" v-if="people.born_address">
              <ion-icon
                name="albums"
                class="bg-gray-100 p-1.5 rounded-full text-xl"
              ></ion-icon>
              <div class="flex-1">
                <div class="">{{ people.born_address }}</div>
              </div>
            </div>
            <div
              class="flex items-center space-x-3"
              v-if="people.current_address"
            >
              <ion-icon
                name="albums"
                class="bg-gray-100 p-1.5 rounded-full text-xl"
              ></ion-icon>
              <div class="flex-1">
                <div class="">{{ people.current_address }}</div>
              </div>
            </div>
            <div class="flex items-center space-x-3" v-if="people.email">
              <ion-icon
                name="mail-open"
                class="bg-gray-100 p-1.5 rounded-full text-xl"
              ></ion-icon>
              <div class="flex-1">
                <div>
                  <a :href="'mailto:' + people.email" class="text-blue-500">{{
                    people.email
                  }}</a>
                </div>
              </div>
            </div>
            <div
              class="flex items-center space-x-3"
              v-if="
                people.dob ||
                people.mob ||
                people.yob ||
                people.dod ||
                people.mod ||
                people.yod
              "
            >
              <ion-icon
                name="calendar"
                class="bg-gray-100 p-1.5 rounded-full text-xl"
              ></ion-icon>
              <div class="flex-1">
                <span v-if="people.dob || people.mob || people.yob"
                  >{{ people.dob ? people.dob + "/" : ""
                  }}{{ people.mob ? people.mob + "/" : ""
                  }}{{ people.yob ? people.yob : "" }}</span
                >
                <span v-if="people.dod || people.mod || people.yod">
                  - {{ people.dod ? people.dod + "/" : ""
                  }}{{ people.mod ? people.mod + "/" : ""
                  }}{{ people.yod ? people.yod : "" }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="widget card p-5 border-t">
          <div class="flex items-center justify-between mb-2">
            <div>
              <h4 class="text-lg font-semibold">
                {{ this.relatedTitle }} liên quan
              </h4>
            </div>
          </div>
          <div>
            <div
              class="flex items-center space-x-4 rounded-md -mx-2 p-2 hover:bg-gray-50"
              :key="'people' + people.id"
              v-for="people in relatedPeople.data"
            >
              <router-link
                :title="people.title"
                class="flex-shrink-0"
                :to="{
                  name: 'PeopleDetail',
                  params: { prefix: this.prefix, slug: this.slug },
                }"
              >
                <img
                  :src="
                    people.avatar
                      ? people.avatar.url
                      : 'https://static.bcdcnt.net/assets/images/avatars/avatar-3.jpg'
                  "
                  class="w-12 h-12 rounded-full object-cover"
                  :alt="people.title"
                />
              </router-link>
              <div class="flex-1">
                <router-link
                  :title="people.title"
                  class="text-base font-semibold capitalize"
                  :to="{
                    name: 'PeopleDetail',
                    params: { prefix: this.prefix, slug: people.slug },
                  }"
                >
                  {{ people.title }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <div class="card p-3 mt-5">
          <latest-comment />
        </div>
      </div>
    </div>
    <div class="">
      <song-list
        :key="'songs' + this.slug"
        v-if="type === 'composer' || type === 'artist' || type === 'poet'"
        :songs="songs"
        v-on:loadSongs="loadSongs"
        title=""
        prefix="bai-hat"
      />
    </div>
    <div class="">
      <song-list
        :key="'folks' + this.slug"
        v-if="type === 'composer' || type === 'artist' || type === 'recomposer'"
        :songs="folks"
        v-on:loadSongs="loadFolks"
        title=""
        prefix="dan-ca"
      />
    </div>
    <div class="">
      <song-list
        :key="'instrumentals' + this.slug"
        v-if="type === 'composer' || type === 'artist'"
        :songs="instrumentals"
        v-on:loadSongs="loadInstrumentals"
        title=""
        prefix="khi-nhac"
      />
    </div>
    <div class="">
      <song-list
        :key="'poems' + this.slug"
        v-if="type === 'poet' || type === 'artist'"
        :songs="poems"
        v-on:loadSongs="loadPoems"
        title=""
        prefix="tieng-tho"
      />
    </div>
    <div class="md:space-x-6 lg:mx-16">
      <div class="card p-5">
        <div
          class="grid md:grid-cols-2 divide divide-gray-200 gap-x-4 mt-4"
          v-if="documents.data.length"
        >
          <related-document-item
            v-for="document in documents.data"
            :document="document"
            :key="'document' + document.id"
          />
        </div>
        <div class="mt-4">
          <v-pagination
            class="justify-content-center"
            v-if="documents.paginatorInfo.lastPage > 1"
            v-model="documents.paginatorInfo.currentPage"
            :pages="documents.paginatorInfo.lastPage"
            :range-size="1"
            active-color="#DCEDFF"
            @update:modelValue="loadRelatedDocuments"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import Comment from "../comment/Comment";
import SongList from "../song/SongList";
import LatestComment from "../comment/LatestComment";
import NotFound from "../404";
import {
  canManagePeople,
  canUpdateAvatar,
  isAdmin,
  changePageTitle,
  changeMetaTag,
  isOwner,
  getSongFieldsByType,
  logActivity,
  thousandFormat,
  numberFormat,
} from "../../core/services/utils.service";
import RelatedDocumentItem from "../document/RelatedDocumentItem";
import UpdateImageModal from "../UpdateImageModal";

export default {
  name: "PeopleDetail",
  components: {
    UpdateImageModal,
    RelatedDocumentItem,
    LatestComment,
    VPagination,
    Comment,
    SongList,
    NotFound,
  },
  data() {
    return {
      type: "",
      slug: "",
      prefix: "",
      commentId: null,
      relatedKey: "",
      relatedTitle: "",
      peopleKey: "",
      editRouter: "",
      url: "",
      image: "https://static.bcdcnt.net/assets/images/bcdcnt-bg.png",
      people: {
        id: 0,
        title: "",
        content: "",
        is_sticky: false,
        avatar: null,
        total_listens: 0,
      },
      songs: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      },
      folks: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      },
      instrumentals: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      },
      poems: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      },
      relatedPeople: {
        data: [],
      },
      documents: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      },
    };
  },
  methods: {
    loadPeople() {
      let query = `query($slug: String!) {
        ${this.type}(slug: $slug) {
          id
          title
          content
          rank
          dob
          mob
          yob
          dod
          mod
          yod
          real_name
          email
          phone
          born_address
          status
          is_sticky
          current_address
          total_listens
          avatar {
            url
            user {
              id
              username
            }
          }
        }
      }
      `;

      ApiService.graphql(query, { slug: this.slug })
        .then(({ data }) => {
          if (data.data && data.data[this.type]) {
            if (data.data[this.type].status !== 1) {
              this.$router.push({ name: "404", params: { catchAll: "404" } });
              return;
            }
            this.people = data.data[this.type];
            this.people.is_sticky = this.people.is_sticky == 1 ? true : false;
            this.loadRelatedPeople();
            this.loadRelatedDocuments();
            logActivity("view", this.type, this.people.id);
            this.url = window.location.href;
            this.image = this.people.avatar
              ? this.people.avatar.url
              : this.image;
            changePageTitle(this.people.title);
            changeMetaTag("description", this.people.content);
            changeMetaTag("title", this.people.title);
            changeMetaTag("image", this.image);
            changeMetaTag("url", this.url);
            changeMetaTag("audio", "");
            changeMetaTag("video", "");
          } else {
            this.people = null;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    resetData() {
      this.songs = {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      };
      this.folks = {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      };
      this.instrumentals = {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      };
      this.poems = {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0,
        },
      };
    },
    stickOrUnstick() {
      if (this.people.is_sticky) {
        this.stickyPeople();
      } else {
        this.unstickyPeople();
      }
    },
    stickyPeople() {
      let query = `mutation($type: String!, $id: ID!) {
        stickyPeople(type: $type, id: $id) {
          id
        }
      }
      `;

      ApiService.graphql(query, { type: this.type, id: this.people.id })
        .then(({ data }) => {
          if (data.data && data.data.stickyPeople) {
            this.$toast.success("Làm nổi bật thành công");
          } else {
            this.$toast.error(data.errors[0].message);
            this.people.is_sticky = 0;
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
          this.people.is_sticky = 0;
        });
    },
    unstickyPeople() {
      let query = `mutation($type: String!, $id: ID!) {
        unstickyPeople(type: $type, id: $id) {
          id
        }
      }
      `;

      ApiService.graphql(query, { type: this.type, id: this.people.id })
        .then(({ data }) => {
          if (data.data && data.data.unstickyPeople) {
            this.$toast.success("Bỏ nổi bật thành công");
          } else {
            this.$toast.error(data.errors[0].message);
            this.people.is_sticky = 1;
          }
        })
        .catch((response) => {
          this.$toast.error(response.message);
          this.people.is_sticky = 1;
        });
    },
    loadSongs(options) {
      let fields = getSongFieldsByType("song", this.type);
      let query = `query($slug: String!, $page: Int, $orderBy: [OrderByClause!]) {
        ${this.type}(slug: $slug) {
          songs(first: 20, page: $page, orderBy: $orderBy) {
            data {
              id
              title
              slug
              views
              downloads
              thumbnail {
                url
              }
              sheet {
                composers(first: 20, orderBy: [{column: "sheet_composer.order", order: ASC}]) {
                  data {
                    id
                    title
                    slug
                  }
                }
              }
              ${fields}
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {
        slug: this.slug,
        page: options.page,
        orderBy: options.orderBy,
      })
        .then(({ data }) => {
          if (data.data && data.data[this.type].songs) {
            this.songs = data.data[this.type].songs;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadFolks(options) {
      let query = `query($slug: String!, $page: Int, $orderBy: [OrderByClause!]) {
        ${this.type}(slug: $slug) {
          folks(first: 20, orderBy: $orderBy, page: $page) {
            data {
              id
              title
              slug
              views
              downloads
              thumbnail {
                url
              }
              artists(first: 10) {
                data {
                  title
                  slug
                  avatar {
                    url
                  }
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {
        slug: this.slug,
        page: options.page,
        orderBy: options.orderBy,
      })
        .then(({ data }) => {
          if (data.data && data.data[this.type].folks) {
            this.folks = data.data[this.type].folks;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadInstrumentals(options) {
      let query = `query($slug: String!, $page: Int, $orderBy: [OrderByClause!]) {
        ${this.type}(slug: $slug) {
          instrumentals(first: 20, orderBy: $orderBy, page: $page) {
            data {
              id
              title
              slug
              views
              thumbnail {
                url
              }
              artists(first: 10) {
                data {
                  title
                  slug
                  avatar {
                    url
                  }
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {
        slug: this.slug,
        page: options.page,
        orderBy: options.orderBy,
      })
        .then(({ data }) => {
          if (data.data && data.data[this.type].instrumentals) {
            this.instrumentals = data.data[this.type].instrumentals;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadPoems(options) {
      let query = `query($slug: String!, $page: Int, $orderBy: [OrderByClause!]) {
        ${this.type}(slug: $slug) {
          poems(first: 20, orderBy: $orderBy, page: $page) {
            data {
              id
              title
              slug
              views
              thumbnail {
              url
            }
              artists(first: 10) {
                data {
                  title
                  slug
                  avatar {
                    url
                  }
                }
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {
        slug: this.slug,
        page: options.page,
        orderBy: options.orderBy,
      })
        .then(({ data }) => {
          if (data.data && data.data[this.type].poems) {
            this.poems = data.data[this.type].poems;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadRelatedPeople() {
      let query = `query($people_id: ID!) {
        ${this.relatedKey}(${this.peopleKey}: $people_id, first: 5) {
          data {
            id
            title
            slug
            avatar {
              url
            }
          }
        }
      }`;

      ApiService.graphql(query, { people_id: this.people.id })
        .then(({ data }) => {
          if (data.data && data.data[this.relatedKey]) {
            this.relatedPeople = data.data[this.relatedKey];
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    loadRelatedDocuments() {
      let query = `query($slug: String!, $page: Int) {
        ${this.type}(slug: $slug) {
          documents(first: 20, orderBy: [{ column: "title", order: ASC }], page: $page) {
            data {
              id
              title
              slug
              thumbnail {
                url
              }
            }
            paginatorInfo {
              currentPage
              lastPage
              total
            }
          }
        }
      }
      `;

      ApiService.graphql(query, {
        slug: this.slug,
        page: this.documents.paginatorInfo.currentPage,
      })
        .then(({ data }) => {
          if (
            data.data &&
            data.data[this.type] &&
            data.data[this.type].documents
          ) {
            this.documents = data.data[this.type].documents;
          }
        })
        .catch((response) => {
          console.log(response);
        });
    },
    reRender() {
      this.loadPeople();
      this.resetData();
    },
    getType(prefix) {
      switch (prefix) {
        case "nghe-si":
          this.type = "artist";
          this.relatedKey = "relatedArtists";
          this.peopleKey = "artist_id";
          this.relatedTitle = "Nghệ sĩ";
          this.editRouter = "AdminArtistEdit";
          break;
        case "nhac-si":
          this.type = "composer";
          this.relatedKey = "relatedComposers";
          this.peopleKey = "composer_id";
          this.relatedTitle = "Nhạc sĩ";
          this.editRouter = "AdminComposerEdit";
          break;
        case "nha-tho":
          this.type = "poet";
          this.relatedKey = "relatedPoets";
          this.peopleKey = "poet_id";
          this.relatedTitle = "Nhà thơ";
          this.editRouter = "AdminPoetEdit";
          break;
        case "soan-gia":
          this.type = "recomposer";
          this.relatedKey = "relatedRecomposers";
          this.peopleKey = "recomposer_id";
          this.relatedTitle = "Soạn giả";
          this.editRouter = "AdminRecomposerEdit";
          break;
      }
    },
    isOwner(user_id) {
      return isOwner(user_id);
    },
    handleReplaceImageFromCommentDone() {
      this.reRender();
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
  },
  mounted() {
    this.prefix = this.$route.params.prefix;
    this.getType(this.$route.params.prefix);
    this.slug = this.$route.params.slug;
    this.commentId = this.$route.query.comment_id;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.prefix = to.params.prefix;
    this.getType(to.params.prefix);
    this.slug = to.params.slug;
    this.commentId = to.query.comment_id;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  },
  computed: {
    canManagePeople() {
      return canManagePeople();
    },
    canUpdateAvatar() {
      return canUpdateAvatar();
    },
    isAdmin() {
      return isAdmin();
    },
  },
};
</script>
